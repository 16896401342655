module.exports = {
    readMoreListener: function (parentClass) {
        var parentString = '.' + parentClass;

        if ($(parentString + ' p').length > 0) {
            $(parentString + ' .text-show.d-none').removeClass('d-none');
            $(parentString + ' p:not(p:first-child)').addClass('d-none');
        }
        $(parentString).removeClass('d-none');

        $(parentString + ' .text-show').on('click', function () {
            var $currentParent = $(this).closest(parentString);
            $currentParent.find('p').removeClass('d-none');
            $currentParent.find('.text-show').addClass('d-none');
            $currentParent.find('.text-hide').removeClass('d-none');
        });

        $(parentString + ' .text-hide').on('click', function () {
            var $currentParent = $(this).closest(parentString);
            $currentParent.find('p:not(p:first-child)').addClass('d-none');
            $currentParent.find('.text-hide').addClass('d-none');
            $currentParent.find('.text-show').removeClass('d-none');
        });
    }
};
